import * as React from "react";
import { graphql } from "gatsby";
import { Link, withPrefix } from 'gatsby';
import {
  Layout, SEO, CBreadCrumb, LWrap, CBtnList
} from "../../../components/_index"
import { size } from "lodash";
import { setLang } from "../../../utils/set-lang"

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter
  return (
    <Layout isKv={false}>
      <SEO title={frontmatter?.title}
        description={frontmatter?.description}
      />
      <CBreadCrumb data={{
        parent: [
          {
            label: "ご利用規約",
            path: "/agreement/"
          }
        ],
        current: {
          label: frontmatter?.title.includes('-') ? frontmatter?.title.split('-')[0] : frontmatter?.title
        }
      }} />
      <section className="l_sect02">
        <LWrap>
          <h1 className="c_headingLv2">オンラインショップシステム利用規約</h1>
          <p className="u_mbLarge">このページは三菱地所ホテルズ＆リゾーツ株式会社のグループホテルに対するオンラインショップシステム利用規約のページです。オンラインショップシステムのご利用に際しましては、必ずお読みいただき、同意いただけますようお願いいたします。<br /><br />三菱地所ホテルズ＆リゾーツ株式会社（以下｢当社｣）は、当社の運営するオンラインショップシステム（以下｢本システム｣）を、お客様にご利用いただくにあたり、次の利用規約を設けさせていただいております。本規約にご同意のうえ、本システムをご利用いただけますよう、お願い申し上げます。</p>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">第1条（本システム利用に際し）</h2>
            <p>1. お客様には、インターネット利用の一般的なマナーや技術的ルールを守っていただきます。<br />
            2. 営利を目的とした本システムの利用は、事由の如何を問わず固くお断りします。</p>
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">第2条（利用規約に反する場合の措置）</h2>
            <p>第三者に迷惑・不利益を与える行為、当社のグループホテルサービスの提供に支障をきたすおそれのある行為等、当社が不適当と判断する行為をおこなうお客様には、本システム、当社のグループホテルのご利用をお断りいたします。</p>
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">第3条（お客様の利用システム環境）</h2>
            <p>本システムは、お客様の利用システムの環境と設定が適切になされていることを前提条件としております。この条件に当てはまらない方の動作結果やそれがもたらす諸影響に関して、当社は一切の責任を負いません。また、この条件を満たしていても、当社の管理の及ばない原因によって、本システムが正しく作動しない場合につきましても、それがもたらす影響に関して、当社は一切の責任を負いません。</p>
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">第4条（本システムの提供サービス）</h2>
            <p>当社が運営しているホームページ（以下、｢本ホームページ｣）上で、当グループホテルが提供するサービスをご利用いただけます。</p>
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">第5条（当システム利用上の注意）</h2>
            <p>本システムの提供するサービスは、本ホームページ以外で提供するサービスに対して優位性を持つものではありません。完売等の理由によりご購入いただけない場合もあります。</p>
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">第6条（本システムご利用者とは）</h2>
            <p>1. 「本システムご利用者」とは、当社が定める手続に従い本規約に同意の上、オンラインショップ商品購入行う個人をいいます。<br />2. 「ご利用者情報」とは、ご利用者が当社に開示したご利用者の属性に関する情報およびご利用者の取引に関する履歴等の情報をいいます。<br />3. 本規約は、すべてのご利用者に適用され、購入手続時およびご購入後にお守りいただく規約です。<br />4. 本システムを利用して、購入手続きをされた時点で、本規約にご同意いただいたとみなします。</p>
          </section>

          <section className="u_mbMedium">
            <h2 className="c_headingLv3">第7条（ご利用者資格の喪失及び自己責任と賠償義務）</h2>
            <p>ご利用者が、ご利用資格取得申込の際に虚偽の申告をしたとき、通信販売による代金支払債務を怠ったとき、その他当社がご利用者として不適当と認める事由があるときは、当社は、ご利用資格を取り消すことができることとします。<br />
            1. 本システムの利用にあたり、お客様の自らの行為およびご自身のメールアカウントよりなされた一切の行為およびその結果について、お客様ご自身の行為の有無、過失の有無を問わず、お客様はその責任を負うものとします。また、本システムのご利用にあたり、当社の過失に因る場合を除き、お客様が第三者に損害を与えた場合、お客様は自己の責任と負担において当該第三者との紛争を解決するものとします。<br />
            2. ご利用者が、以下の各号に定める行為をしたときは、これにより当社が被った損害を賠償する責任を負います。<br />
            ・ご利用ID、パスワードを不正に利用すること<br />
            ・第三者の情報の送信、書き込みを行った場合。<br />
            ・当ホームページにアクセスして情報を改ざんしたり、当ホームページに有害なコンピュータプログラムを送信するなどして、当社の営業を妨害すること<br />
            ・当社が扱う商品の知的財産権を侵害する行為をすること<br />
            ・お客様の私的利用以外の目的で、当社に無断で利用した場合。<br />
            ・その他日本国内で有効な法令に違反する行為を行った場合。<br />
            ・その他、この利用規約に反する行為をすること
            </p>
          </section>

          <section className="u_mbMedium">
            <h2 className="c_headingLv3">第8条（購入の申込）</h2>
            <p>本システムを利用しての購入申込の場合、希望する商品の購入申込フォームへ必要とされるデータを全て正確に入力してください。<br />
            ザ クラブ会員の方は、会員No.またはメールアドレスおよびパスワードを入力することで、本システムの会員専用ページにて会員価格で購入の申込をすることができます。一部の商品は、割引・ポイント付与の対象外となります<br />
            注文申込フォームへのデータ入力に不備があった場合、その注文は無効となる場合があります。</p>
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">第9条（購入の成立）</h2>
            <p>本システムを利用した購入の成立は、注文決定確認書がメール送信された時といたします。</p>
          </section>

          <section className="u_mbMedium">
            <h2 className="c_headingLv3">第10条（購入の取り消しと変更）</h2>
            <p className="u_mb20">本システムを利用した購入の取消し（キャンセル）と変更については、各グループホテルの特定商取引に関する法律に基づく表示に準じます。<br />なお、詳細は当該ホテルまたは施設までお電話にてお問合せください。</p>
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">第11条（ご利用者情報の取扱い）</h2>
            <p className="u_mb20">ご利用者情報については、当社のプライバシーポリシーに基づき取り扱いいたします。プライバシーポリシーの詳細は<Link to="https://www.royalparkhotels.co.jp/privacy/" target="_blank" rel="noopner noreferrer">こちら</Link>をご覧ください。</p>
          </section>

          <section className="u_mbMedium">
            <h2 className="c_headingLv3">第12条（禁止事項）</h2>
            <p className="u_mb20">本サービスの利用に際して、ご利用者に対し次の各号の行為を行うことを禁止します。<br />
            1. 法令または本規約、本サービスご利用上のご注意、本サービスでのお買い物上のご注意、その他の規約等に違反すること<br />
            2. 当社、およびその他の第三者の権利、利益、信用、名誉等を損ねること<br />
            3. 青少年の心身に悪影響を及ぼす恐れがある行為、その他公序良俗に反する行為を行うこと<br />
            4. 他の利用者その他の第三者に迷惑となる行為や不快感を抱かせる行為を行うこと<br />
            5. 虚偽の情報を入力すること<br />
            6. 有害なコンピュータプログラム、メール等を送信または書き込むこと<br />
            7. 当社のサーバその他のコンピュータに不正にアクセスすること<br />
            8. パスワードを第三者に貸与・譲渡すること、または第三者と共用すること<br />
            9. 反社会的勢力と関わること<br />
            10. その他当社が不適切と判断すること
            </p>
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">第13条（システム内容の変更・停止）</h2>
            <p className="u_mb20">本システムの運営もしくは内容の変更は、当社が必要と判断した場合、事前にお客様に通知することなく変更を行う場合があります。本システムのご利用に際しましては、毎回必ず本規約をご確認ください。本規約内容の変更後は、変更後の内容のみが有効となり、変更前の内容は無効となります。</p>
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">第14条（システムの中断）</h2>
            <p className="u_mb20">当社は、次のいずれかに該当する場合、お客様への事前の通知や承諾なしに、本システムの一時的な利用の中断、停止をおこなうことがあります。<br />
            1. 本システムの保守または工事をおこなう場合。<br />
            2. システムに負荷が集中した場合。<br />
            3. 天災、事変その他非常事態が発生し、または発生のおそれがあり、本システムの運営が困難となった場合。<br />
            4. 当社が、上記以外の事由により、本システムの一時的な中断が必要と判断した場合。</p>
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">第15条（免責）</h2>
            <p className="u_mb20">1. 通信回線やコンピュータなどの障害によるシステムの中断・遅滞・中止・データの消失、データへの不正アクセスにより生じた損害、その他当社のサービスに関してご利用者に生じた損害について、当社は一切責任を負わないものとします。<br />
            2. 当社は、当社のウェブページ・サーバ・ドメインなどから送られるメール・コンテンツに、コンピュータ・ウィルスなどの有害なものが含まれていないことを保証いたしません。<br />
            3. ご利用者が本規約等に違反したことによって生じた損害については、当社は一切責任を負いません。
            </p>
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">第16条（本規約の変更）</h2>
            <p className="u_mb20">1. 本規約は、民法上の定型約款に該当し、本規約の各条項は、お客様の一般の利益に適合する場合または変更を必要とする相当の事由があると認められる場合には、民法の規定に基づいて変更します。<br />
            2. 当社において本規約を補充する規約 （以下「補充規約」といいます） を定めることができます。<br />
            3. 本規約の変更及び補充規約の制定は、変更または制定後の規定の内容を、当社所定のウェブサイトに掲載し、掲載の際に定める効力発生日から適用されます。
            </p>
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">第17条（取り扱い法令）</h2>
            <p className="u_mb20">本システムに関する取り扱いは、日本国内にて有効な法律に従います。</p>
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">第18条（準拠法、管轄裁判所）</h2>
            <p className="u_mb20">本規約に関して紛争が生じた場合、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</p>
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">第19条（本規約の有効）</h2>
            <p className="u_mb20">本規約は、2021年6月30日より有効とします。なお、当社は社会経済情勢の変化ならびに諸般の事情で、本規約の改正と変更を、お客様への事前の通知無しに出来るものとします。</p>
          </section>
        </LWrap>
      </section>
    </Layout>
  )
}

export default SubPage;

export const query = graphql`
  query ($slug: String!){
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`